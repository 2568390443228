<template>
  <div class="unity">
    <div v-show="isLoading" class="unity_loading">
      正在加载...
      <el-progress style="width: 100%;" :percentage="percentage" color="#4766FF"></el-progress>
    </div>
    <div v-show="!isLoading" class="unity_container">
      <div class="unity_container_canvas">
        <div style="width: 100%; height: calc(100% - 84px)">
          <canvas id="unity-canvas"  ref="unityCanvas" style="display: block; width: 100%;height: 100%;"></canvas>
        </div>
      </div>
      <div class="unity_send">
        <div class="bg_white">
          <div class="voice">
            <div class="voice_icon">
              <img v-show="!isVoice" src="../assets/img/voice.png" alt=""  @click="isVoice = true">
              <img v-show="isVoice" src="../assets/img/keyboard.png" alt=""  @click="isVoice = false">
            </div>
          </div>
          <div class="content_box">
            <button v-show="isVoice" @mousedown="mousedown" @mouseup="mouseup">
              <span v-if="!isSaying">长按说话</span>
              <img v-else src="../assets/img/webp.webp" alt="">
            </button>
            <div v-show="!isVoice" class="content_key_send">
              <input placeholder="请输入您的问题" type="text" v-model="inputText" @keypress="keypress"  @keydown.delete.prevent="clearInputValue" @input="handleSendImg" @keydown.enter="handleSendMsg"></input>
              <img id="myImg" src="../assets/img/send_l.png" @click="handleSendMsg" />
            </div>
          </div>
        </div>
      </div>
      <p style="position: absolute;bottom: 0;left: 30%;">此内容由AI生成</p>
    </div>
  </div>
</template>

<script>
import { initRecord } from '../utils/globalMethods'
import Utiles from '@/utils/utiles'
import axios from 'axios'
import indexedDB from '@/utils/indexdb.js'


export default {
  name: 'AIPerson',
  data() {
    return {
      dataUrl: "https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/webgl2.data",
      frameworkUrl: "https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/webgl2.framework.js",
      codeUrl: "https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/webgl2.wasm",
      inputText: '',
      showSlid: false,
      UnityIns: null,
      // 发送方式-默认语音
      isVoice: true,
      // 数字人加载中
      isLoading: true,
      // 加载进度
      percentage: 0,
      // 录音中
      isSaying: false,
    }
  },
  mounted() {
    const key = "version_data"
    const table = 'sdk'
    // 先获取dataurl是否存在
    indexedDB.getData(table,key).then(event=>{
        console.log(event)
        if (event.target.result) {
          // 拿到了数据
          var t = Number(event.target.result.data["updatetime"]);
          var storekey = event.target.result.data["store_key"];
          var timenow = Date.now();
          console.log("get data", t, storekey,timenow - t < 1000*60*60*24*7 ,storekey === this.dataUrl + this.frameworkUrl + this.codeUrl)
          if (timenow - t < 1000*60*60*24*7 && storekey === this.dataUrl + this.frameworkUrl + this.codeUrl) {
            // 拿到了数据,且未过期,校验通过，走缓存逻辑
            console.log("start load person from cache")
            this.loadPerson("0")
            return
          } 
        } 
        // 没拿到数据,先主动缓存，再走缓存逻辑
        this.getDataFromUrl().then(this.storeData).then(res=>this.loadPerson("0")).then(res=>{},reject=>this.loadPerson("1"))
    },reason=> {
      console.log('read faild', reason);
      // 没拿到数据
      this.getDataFromUrl().then(this.storeData).then(res=>this.loadPerson("0")).then(res=>{},reject=>this.loadPerson("1"))
    }
    )
    // 加载unity数字人

    Utiles.$on('sendMsg', (val) => {
      this.sendWavData(val)
    })
    Utiles.$on('tounitytss', (val) => {
      this.UnityIns.SendMessage('GameMain','ToUnityTTS', val)
      // UnityIns.SendMessage('GameMain','ToUnityPlayAni','Anim_Idle')
      // UnityIns.SendMessage('GameMain','ToUnityPlayAni','Anim_Think')
      // this.UnityIns.SendMessage('GameMain','ToUnityPlayAni','Anim_Hello')
      // this.UnityIns.SendMessage('GameMain','ToUnityPlayAni','Anim_SayError')
    })
    Utiles.$on('tounitytext', (val) => {
      this.UnityIns.SendMessage('GameMain','ToUnityText', val)
      this.UnityIns.SendMessage('GameMain','ToUnityPlayAni','anim_Think')
      // 清空正在播放的视频或者图片
      Utiles.$emit('clearSrc')
    })
    Utiles.$on('sendProgress', (val) => {
      this.isLoading = true
      const progress = Math.round(val * 100)
      this.percentage = progress
      if (this.percentage == 100) {
        this.isLoading = false
        // 缓存网络错误
        console.log('加载完成')
        this.UnityIns.SendMessage('GameMain', 'TounitvPreNetErponITS')
      }
    })
    Utiles.$on('SendPlayAnimationFinish', (val) => {
      if (val == 'anim_ShortIdle') return
      this.UnityIns.SendMessage('GameMain','ToUnityPlayAni','anim_ShortIdle')
    })
    Utiles.$on('UnitySendEvent', () => {
      this.UnityIns.SendMessage('GameMain','ToUnityPlayAni','anim_Say')
    })
    Utiles.$on('SendPlayAllCompleteToNative', () => {
      this.UnityIns.SendMessage('GameMain','ToUnityPlayAni','anim_ShortIdle')
    })
    Utiles.$on('tounityErrortss', (val) => {
      this.UnityIns.SendMessage('GameMain','ToUnityNetErrorTTS', val)
    })
  },
  methods: {
    loadPerson(noCache){
        console.log("load person,cache is---------->", noCache)
        const canvas = this.$refs.unityCanvas
        createUnityInstance(canvas, {
          dataUrl: this.dataUrl,
          frameworkUrl: this.frameworkUrl,
          codeUrl: this.codeUrl,
          // dataUrl: "./build/webgl2.data",
          // frameworkUrl: "./build/webgl2.framework.js",
          // codeUrl: "./build/webgl2.wasm",
          streamingAssetsUrl: "StreamingAssets",
          companyName: "DefaultCompany",
          productName: "TestLipSync",
          backgroundColor: "transparent",
          productVersion: "0.1",
          noCache,
          indexedDBName: "4u",
          indexedDBCache: "sdk",
        }).then((unityInstance) => {
          this.UnityIns = unityInstance
          // 角色选择
          //this.UnityIns.SendMessage('GameMain','ToUnityAvatar','ayang')
          // 可以自行配置id
          // this.UnityIns.SendMessage('GameMain','ToUnityInit',{
          //   "XunfeiAppID": "your_xunfei_app_id",
          //   "XunfeiAPIKey": "your_xunfei_api_key",
          //   "APISecret": "your_api_secret",
          //   "DeviceId": "your_device_id",
          //    'fps': '1'
          // })
          initRecord()
        }).catch((message) => {
          alert(message);
        })
    },
    // 切片像unity发送音频数据
    sendWavData(blob) {
      var reader = new FileReader()
      const _this = this
      reader.onload = function (e) {
        var _value = reader.result;
        var _partLength = 8192;
        var _length = parseInt(_value.length / _partLength)
        if (_length * _partLength < _value.length) _length += 1
        var _head = "Head|" + _length.toString() + "|" + _value.length.toString() + "|end"
        // 发送数据头
        _this.UnityIns.SendMessage("SignalManager", "GetAudioData", _head)
        for (var i = 0; i < _length; i++) {
          var _sendValue = ""
          if (i < _length - 1) {
            _sendValue = _value.substr(i * _partLength, _partLength)
          } else {
            _sendValue = _value.substr(
              i * _partLength,
              _value.length - i * _partLength
            )
          }
          _sendValue = "Part|" + i.toString() + "|" + _sendValue
          // 发送分片数据
          _this.UnityIns.SendMessage("SignalManager", "GetAudioData", _sendValue)
        }
        _value = null
      }
      reader.readAsDataURL(blob)
    },
    // 鼠标离开
    mouseup(event) {
      this.isSaying = false
      this.UnityIns.SendMessage('GameMain','ToUnityStopMic','')
    },
    // 录音按钮点击事件
    mousedown() {
      this.UnityIns.SendMessage('GameMain','ToUnityStartMic','')
      this.isSaying = true
    },
    // 回车发消息
    handleSendMsg() {
      this.UnityIns.SendMessage('GameMain','ToUnityText',this.inputText)
      // 清空数据
      this.inputText = ''
      // 清空正在播放的视频或者图片
      Utiles.$emit('clearSrc')
    },
    keypress(e){
      var c = String.fromCharCode(e.charCode || e.which)
      this.inputText = this.inputText + c
      this.handleSendImg()
    },
    clearInputValue(event) {
      // 获取当前光标位置
      const cursorPosition = event.target.selectionStart
      const endPosition = event.target.selectionEnd
      let pos = cursorPosition - 1
      if (pos<0) {
        pos = 0
      }
      // 删除光标位置及其前一个字符
      if (cursorPosition == endPosition) {
        this.inputText = this.inputText.slice(0, pos) + this.inputText.slice(cursorPosition)
      } else {
        this.inputText = this.inputText.slice(0, cursorPosition) + this.inputText.slice(endPosition)
        pos = cursorPosition
      }
      this.$nextTick(()=>{
        // 设置光标位置
        event.target.selectionStart = pos
        event.target.selectionEnd = pos
      })
      this.handleSendImg()
    },
    // 判断发送按钮
    handleSendImg() {
      const image = document.getElementById('myImg')
      // 检查输入框是否有内容
      if (this.inputText.trim() !== '') {
        // 如果有内容，将图片透明度设置为1
        image.style.opacity = '1'
      } else {
        // 如果没有内容，将图片透明度恢复为默认值0.3
        image.style.opacity = '0.3'
      }
    },
    getDataFromUrl(){
      const urls = [
        this.dataUrl,this.frameworkUrl,this.codeUrl
      ];

      return Promise.all(urls.map(url => axios.get(url,{responseType: "arraybuffer"})))
    },
    storeData(res){
      const table = 'sdk'
      var now = Date.now();
      console.log('start store data',res)
      return Promise.all([
        indexedDB.updateDB(table,this.dataUrl,res[0].data),
        indexedDB.updateDB(table,this.frameworkUrl,res[1].data),
        indexedDB.updateDB(table,this.codeUrl,res[2].data),
        indexedDB.updateDB(table,"version_data",{"updatetime":now,"store_key":this.dataUrl+this.frameworkUrl+this.codeUrl})]
      )
    }
  }
}
</script>
<style scoped>
.unity {
  width: 17.5%;
  min-width: 200px;
  height: 62%;
  min-height: 400px;
  border-radius: 16px 16px 16px 16px;
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.unity_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #E6F0FA 0%, #C5D2EB 100%);
}

.unity_container {
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.unity_container_canvas {
  width: 100%;
  height: 100%;
}
.unity_send {
  width: 88%;
  height: 44px;
  position: absolute;
  bottom: 40px;
  left: 6%;
}
.bg_white {
  width: 100%;
  align-items: center;
  background: #F5F8FF;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  display: flex;
  padding: 4px 4px 4px 2px;
}
.voice {
  width: 36px;
  height: 36px;
}
.voice_icon {
  width: 36px;
  height: 36px;
  margin-right: 2px;
  cursor: pointer;
}

.content_box {
  display: flex;
  height: 36px;
  flex-grow: 1;
  align-items: center;

  button {
    width: 100%;
    height: 36px;
    background: #4766FF;
    border-radius: 6px 6px 6px 6px;
    border: 0;
    font-size: 14px;
    color: #fff;
    cursor: pointer;

    img {
      width: 156px;
      height: 36px;
    }
  }
}

.content_key_send {
  width: 100%;
  height: 36px;
  background: #F5F8FF;
  border-radius: 6px 6px 6px 6px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 36px;
    border: 0;
    background: #F5F8FF;
    border-radius: 6px;
    padding-left: 10px;
  }

  input:focus {
    outline: none;
    order: none;
  }

  input::-webkit-input-placeholder {
    color: #B8BECC;
  }
  input::-moz-placeholder {
    color: #B8BECC;
  }
}

#myImg {
  opacity: 0.3;
  cursor: pointer;
}
</style>