<template>
    <div>
      <div id="hero" class="hero">
        <div class="hero__container-2">
          <div data-w-id="ba7e14e6-1312-6bb5-7ca9-eec213300367" class="hero__container-inner-2">
            <div class="hero__scroll-container-2">
              <div class="hero__transform-target-2">
                <p class="hero__intro-2">领先的数字人实时互动系统，为您的业务带来全新的互动体验！</p>
                <div class="hero__headline">
                  <h1 class="hero__headline-text-2">波塔BOTA<br>AI+数字人专家</h1><img src="../assets/images/头图3840无字.png" loading="eager" alt="" class="hero__hand"><img src="../assets/images/官网底图曲面2808.png" loading="eager" alt="" data-w-id="ba7e14e6-1312-6bb5-7ca9-eec213300372" class="hero__ui">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="intro" class="intro">
        <div class="intro__logos">
          <div class="intro__logos-wrapper"><img src="../assets/images/Mask-group-11.png" alt="" class="intro__logo intro__logo--rmargin"><img src="../assets/images/Mask-group-6.png" alt="" class="intro__logo intro__logo--rmargin"><img src="../assets/images/Mask-group-3.png" alt="" class="intro__logo intro__logo--rmargin"><img src="../assets/images/Mask-group-10.png" alt="" class="intro__logo intro__logo--rmargin"><img src="../assets/images/Mask-group-4.png" alt="" class="intro__logo"></div>
          <div class="intro__logos-wrapper"><img src="../assets/images/Mask-group.png" alt="" class="intro__logo intro__logo--rmargin"><img src="../assets/images/Mask-group-9.png" alt="" class="intro__logo intro__logo--rmargin"><img src="../assets/images/Mask-group-8.png" alt="" class="intro__logo intro__logo--rmargin"><img src="../assets/images/Mask-group-7.png" alt="" class="intro__logo"></div>
        </div>
        <div class="intro__workflow-2">
          <div data-w-id="4801e91f-8e6e-a7fb-5056-86dc56a09aa4" class="intro__workflow-text-2">
            <h2 class="intro__workflow-heading-2">波塔(BOTA)<br>实时互动数字人专家</h2>
            <p class="intro__workflow-paragraph-2">波塔是领先的数字人实时互动系统，为您的业务带来全新的互动体验！波塔系统可以加载3D超写实和1:1真人复刻数字人，配合强大的语言模型，使数字人能够以自然的语音与人进行交流。无论是数字员工的应用、展厅讲解的场景，还是电商直播的需求，波塔都能够完美适配，让AI“活”起来。</p>
          </div><img src="../assets/images/3d2d配图.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 706.5546875px, (max-width: 1279px) 60vw, (max-width: 1439px) 50vw, (max-width: 1919px) 51vw, 886.6640625px" srcset="../assets/images/3d2d配图-p-500.png 500w, images/3d2d配图-p-800.png 800w, ../assets/images/3d2d配图-p-1080.png 1080w, ../assets/images/3d2d配图-p-1600.png 1600w, ../assets/images/3d2d配图-p-2000.png 2000w, ../assets/images/3d2d配图.png 2400w" alt="" class="image-4">
        </div>
      </div>
      <div id="botax" class="testimonials">
        <h2 class="testimonials__heading">AI数字人+硬件，适用业务全场景</h2>
        <div class="testimonials__wrapper">
          <div class="testimonials__card">
            <div class="testimonials__card-content">
              <h3 class="heading">立式一体机</h3>
              <div class="testimonials__card-blockquote">立式屏一体机具备降噪阵列麦克风、摄像头和高灵敏度触控屏幕。占地小，移动方便，带来便捷的智能互动体验，大幅降低了使用和维护成本，是各类场所提升服务和效率的理想选择。</div>
              <div class="testimonials__card-info"><img src="../assets/images/配图1.png" loading="lazy" alt="" class="testimonials__card-photo"></div>
            </div>
          </div>
          <div class="testimonials__card">
            <div class="testimonials__card-content">
              <h3 class="heading">展厅大屏</h3>
              <div class="testimonials__card-blockquote">波塔实时互动数字人系统为展厅大屏幕赋能，提供数字人导览和讲解服务，提升参观体验。凭借智能互动和生动展示，波塔系统为您的展厅带来前所未有的便捷和高效，极大增强了展示效果和客户满意度。</div>
              <div class="testimonials__card-info"><img src="../assets/images/配图2.png" loading="lazy" alt="" class="testimonials__card-photo"></div>
            </div>
          </div>
          <div class="testimonials__card">
            <div class="testimonials__card-content">
              <h3 class="heading">企业个性设备</h3>
              <div class="testimonials__card-blockquote">波塔实时互动数字人系统可加载在企业的个性展示设备上，通过先进的人工智能技术，让展示设备焕发新生，提升客户体验，增加互动性，为企业带来便捷、高效的展示解决方案。</div>
              <div class="testimonials__card-info"><img src="../assets/images/配图6.png" loading="lazy" alt="" class="testimonials__card-photo"></div>
            </div>
          </div>
        </div>
        <div class="hero__cta-section">
          <h4 class="hero__cta-heading">免费获取你的企业AI数字人落地方案</h4>
          <a data-w-id="e8cbcc0d-91c0-75a7-1b04-e43717c6a364" href="#" class="button-2 w-button">免费获取</a>
        </div>
      </div>
      <div id="live" class="decks">
        <div data-w-id="22320839-ee28-8005-eec9-1d09377c883c" class="decks__text">
          <h2 class="decks__text-heading">专业的数字人直播系统</h2>
          <p class="decks__text-paragraph">数字人直播软件是一个引领直播未来的创新平台。数字人直播则能通过人工智能技术自动生成虚拟形象，无需雇佣大量人员进行直播。它能够节省大量的人力成本、场地成本、设备成本以及管理成本，使得企业能够节省大量成本，大大提高运营效率。</p>
        </div>
        <div data-w-id="627f0563-f0d5-b558-fb7a-1d657a7d85cf" class="decks__wrapper">
          <div data-w-id="fcf9ea41-4f2c-c434-dc88-fc9c55224826" class="decks__grid decks__grid--margin"><img src="../assets/images/Group-19_1.jpg" alt="" class="decks__image decks__image--margin"><img src="../assets/images/Group-30.png" alt="" class="decks__image decks__image--margin"></div>
          <div data-w-id="24a019eb-ef3e-9542-381b-76cd33933238" class="decks__grid decks__grid--margin"><img src="../assets/images/Group-20_1.png" alt="" class="decks__image decks__image--margin"><img src="../assets/images/Group-29.png" alt="" class="decks__image decks__image--margin"></div>
          <div data-w-id="210c3b8c-8fd7-0a9a-caff-db12c42e1f7a" class="decks__grid decks__grid--margin"><img src="../assets/images/Group-32_1.png" alt="" class="decks__image decks__image--margin"><img src="../assets/images/Group-26.jpg" alt="" class="decks__image decks__image--margin"></div>
          <div data-w-id="07df8612-3a03-303f-392f-46aa4c3e2cf9" class="decks__grid decks__grid--margin"><img src="../assets/images/Group-24.png" alt="" class="decks__image decks__image--margin"><img src="../assets/images/Group-19.jpg" alt="" class="decks__image decks__image--margin"></div>
          <div data-w-id="0c0bbed3-3659-0a0f-0b3f-552eba1e020d" class="decks__grid decks__grid--margin"><img src="../assets/images/Group-21.png" alt="" class="decks__image decks__image--margin"><img src="../assets/images/Group-32.png" alt="" class="decks__image decks__image--margin"></div>
          <div data-w-id="fb9f769f-319d-09ed-7b22-babe47ea2fa7" class="decks__grid decks__grid--margin"><img src="../assets/images/Group-23.png" alt="" class="decks__image decks__image--margin"><img src="../assets/images/Group-20.png" alt="" class="decks__image decks__image--margin"></div>
          <div data-w-id="f5fb0b2e-6ada-9ff5-79c8-121ea2c0d91d" class="decks__grid"><img src="../assets/images/Group-22.png" alt="" class="decks__image decks__image--margin"><img src="../assets/images/Group-30.png" alt="" class="decks__image decks__image--margin"></div>
        </div>
        <div class="decks__overlay"></div>
      </div>
      <div id="forcast" class="sharing">
        <div data-w-id="56bd0bd2-f2bb-6dca-aa4d-386a1e522b6b" class="sharing__text">
          <h2 class="sharing__text-heading">数字主持人+3D场景，呈现创新天气预报</h2>
          <p class="sharing__text-paragraph">我们的实时数字人气象预报产品通过将3D场景与数字节目主持人结合，能够实时更新气象节目，一键生成精准的天气预报。大大降低了节目制作成本，并且极大的提高了节目制作效率。</p>
        </div>
        <div class="sharing__wrapper">
          <div data-w-id="391a92d5-f929-7657-fd02-b0fa50017ac8" class="sharing__plane-wrapper"><img src="../assets/images/shadow_1shadow.png" alt="" data-w-id="f685e59e-c3c2-6493-1410-814738884483" class="sharing__plane-shadow"></div><img src="../assets/images/气象配图.png" alt="" class="sharing__image">
        </div>
      </div>
      <div id="tech" class="formatting">
        <div class="formatting__wrapper">
          <div class="lottie-animation" data-w-id="93e49d4d-f42c-7c09-90e7-11eaf316d745" data-animation-type="lottie" data-src="https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/AI.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-duration="0"></div>
          <div class="formatting__text">
            <h2 data-w-id="3a91c95d-0a43-9339-c021-4d0f49453d72" class="formatting__heading">AI+ 企业知识库打造专属大脑</h2>
            <p data-w-id="d2ae5211-a84e-1d29-028c-f94ab4fc49e8" class="formatting__paragraph formatting__paragraph--space">波塔数字人具备强大的AI智能能力，融合了先进的大语言模型和企业知识库。<br></p>
            <p data-w-id="c79894c1-3b77-3bbb-107d-02d42e5bc5fe" class="formatting__paragraph">它能够根据企业的业务需求进行精准的信息处理和互动服务，提供专业的解答和个性化的支持。<br></p>
          </div>
        </div>
      </div>
      <div class="integrations">
        <div class="integrations__wrapper">
          <div data-w-id="80701129-0266-ce0a-9b7d-fecbbf6dd68e" class="integrations__text">
            <h2 class="integrations__text-heading">基于深度神经网络的真实发声</h2>
            <p class="integrations__text-paragraph">波塔数字人不仅具备智能对话能力，其说话声音也非常真实，仿若真人交流。通过先进的语音合成技术，它能自然流畅地表达情感和信息，为用户带来逼真的互动体验，显著提升服务质量和用户满意度。</p>
          </div>
          <div class="lottie-animation-2" data-w-id="69b5af5c-fd9f-4aae-7002-a63c749416d2" data-animation-type="lottie" data-src="https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/voice.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="4" data-duration="0"></div>
        </div>
      </div>
      <div data-w-id="6bd897d0-9409-6f60-fa1a-568e44295e33" class="formatting">
        <div class="formatting__wrapper">
          <div class="lottie-animation" data-w-id="6bd897d0-9409-6f60-fa1a-568e44295e35" data-animation-type="lottie" data-src="https://zqinghui.oss-cn-zhangjiakou.aliyuncs.com/test/guanwang/api.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="5" data-duration="0"></div>
          <div class="formatting__text">
            <h2 class="formatting__heading">API能力轻松与企业链接</h2>
            <p class="formatting__paragraph formatting__paragraph--space">波塔BOTA的API为您的企业注入强大的AI能力，给产品轻松集成实时互动数字人功能。通过BOTA API，您可以程序化地创建智能互动体验，实现高度真实的语音和表情互动，为企业AI+赋能。<br></p>
          </div>
        </div>
      </div>
      <div v-if="videoUrl" class="about__investors">
        <video id="my-video" ref="videoElement" :src="videoUrl" autoplay controls></video>
      </div>
      <div v-if="imgUrl" class="about__investors">
        <img :src="imgUrl" id="my-video" >
      </div>
      <div class="about__investors">
        <h2 class="about__investors-heading">公司介绍</h2>
        <p class="decks__text"> 世优科技成立于2015年，是领先的虚拟技术解决方案提供商，专注于数字人全栈技术和全场景应用的研发，在底层算法、产品技术、市场运营、客户服务等方面有丰富经验和领先优势。目前已为包括央视、中国移动、华为、腾讯、阿里巴巴、海尔集团等世界500强在内的，近1000家客户提供产品或服务，累计打造数字人超过1000个。2022年、2023年世优科技先后完成A+轮、A++轮、B轮三次超过2亿元的战略融资。</p>
        <div class="about__investors-container">
          <div class="about__investors-wrapper"><img src="../assets/images/1.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--50space">
            <div class="about__investors-info">国家高新技术企业</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/2.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--34space">
            <div class="about__investors-info">中关村高新技术企业</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/3.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--34space">
            <div class="about__investors-info">“专精特新”小巨人企业</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/4.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--50space">
            <div class="about__investors-info">近10年虚拟技术研发</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/5.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--50space">
            <div class="about__investors-info">40+国家发明专利</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/6.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--27space">
            <div class="about__investors-info">100+计算机软著</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/7.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--27space">
            <div class="about__investors-info">50+行业技术奖项</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/8.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--27space">
            <div class="about__investors-info">1000+虚拟IP打造</div>
          </div>
          <div class="about__investors-wrapper"><img src="../assets/images/9.png" loading="lazy" alt="" class="about__investors-logo about__investors-logo--27space">
            <div class="about__investors-info">1000+服务客户<br></div>
          </div>
        </div>
      </div>
      <div data-w-id="e9fc6c8a-8608-ec94-6d7f-9f3d7ed67033" class="news">
        <div class="news__wrapper">
          <div data-w-id="aeaddfa1-fe6f-f19a-4873-994ed5f8233e" class="news__card">
            <div class="news__card-content">
              <a href="#" class="news__card-linkh w-inline-block">
                <h3 class="news__card-heading">波塔(BOTA) 2024发布会</h3>
              </a><img src="../assets/images/发布会横板海报1.jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 77vw, (max-width: 991px) 76vw, (max-width: 1279px) 32vw, (max-width: 1919px) 29vw, 544.9921875px" srcset="../assets/images/发布会横板海报1-p-500.jpg 500w, ../assets/images/发布会横板海报1-p-800.jpg 800w, ../assets/images/发布会横板海报1.jpg 1370w" class="news__card-image">
            </div>
            <a data-w-id="2dbd9838-356a-6ae5-03be-2f6e432aa728" href="#" class="news__card-button w-inline-block">
              <div data-w-id="809ec9d3-fa1d-96e6-dd7b-d891b692240e" class="news__card-buttontext">了解更多</div>
              <div data-w-id="4a468bfa-12a4-4f9a-534e-0a3c42d11fe8" class="news__card-buttonarrow w-embed"><svg width="27" height="8" viewbox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M26.3536 4.35355C26.5488 4.15829 26.5488 3.84171 26.3536 3.64645L23.1716 0.464466C22.9763 0.269204 22.6597 0.269204 22.4645 0.464466C22.2692 0.659728 22.2692 0.976311 22.4645 1.17157L25.2929 4L22.4645 6.82843C22.2692 7.02369 22.2692 7.34027 22.4645 7.53553C22.6597 7.7308 22.9763 7.7308 23.1716 7.53553L26.3536 4.35355ZM0 4.5H26V3.5H0V4.5Z" fill="currentColor"></path>
                </svg></div>
            </a>
          </div>
          <div data-w-id="cec6992c-343a-21b2-6b3c-755a1797920c" class="news__card news__card--purple">
            <div class="news__card-content">
              <h3 class="news__card-heading news__card-heading--white">与我们合作，成为波塔(BOTA)渠道商</h3>
              <p class="news__card-paragraph">加入波塔BOTA实时互动数字人渠道商计划，携手引领智能互动新时代。成为渠道商，您将获得全方位的支持和优厚的回报，共同开拓广阔的市场机会，推动企业数字化转型。</p>
            </div>
            <a data-w-id="cec6992c-343a-21b2-6b3c-755a17979211" href="#" class="news__card-button w-inline-block">
              <div data-w-id="cec6992c-343a-21b2-6b3c-755a17979212" class="news__card-buttontext news__card-buttontext--white">立即联系</div>
              <div data-w-id="cec6992c-343a-21b2-6b3c-755a17979214" class="news__card-buttonarrow news__card-buttonarrow--white w-embed"><svg width="27" height="8" viewbox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M26.3536 4.35355C26.5488 4.15829 26.5488 3.84171 26.3536 3.64645L23.1716 0.464466C22.9763 0.269204 22.6597 0.269204 22.4645 0.464466C22.2692 0.659728 22.2692 0.976311 22.4645 1.17157L25.2929 4L22.4645 6.82843C22.2692 7.02369 22.2692 7.34027 22.4645 7.53553C22.6597 7.7308 22.9763 7.7308 23.1716 7.53553L26.3536 4.35355ZM0 4.5H26V3.5H0V4.5Z" fill="currentColor"></path>
                </svg></div>
            </a>
          </div>
        </div>
      </div>
      <div data-w-id="bdf098ff-989b-cdaa-c443-d2da89257efb" class="cta">
        <div class="cta__container">
          <h2 class="cta__heading">定制企业AI+实时互动数字人方案</h2>
          <a data-w-id="8655a7e5-3a5b-148f-279c-471460229306" href="#" class="button-3 w-button">免费获取</a>
        </div>
      </div>
      <div class="footer">
        <el-dialog :visible.sync="isShowWord" :title="typeTitle" :before-close="handleClose" width="50%">
          <div id="wordView" class="word_view" v-html="wordText" />
        </el-dialog>
        <div class="footer__wrapper">
          <div id="w-node-_52689639-77f9-af09-ed7f-733cbb12f40d-bb12f40b" class="footer__credits"><img src="../assets/images/logo-3.png" loading="lazy" alt="" class="image-7">
            <div class="footer__copyright"><span style="cursor: pointer; margin-right: 30px" @click.stop="openWordFn('user')">用户协议</span><span style="cursor: pointer;" @click.stop="openWordFn('privacy')">隐私政策</span><br>© botax 2024 世优（北京）科技股份有限公司<br><span style="cursor: pointer;" @click="handleClick">京ICP备15044130号-9</span></div>
          </div>
          <div class="footer__links-wrapper">
            <h6 class="footer__heading"><strong>联系我们</strong></h6>
            <div class="footer__copyright">公司名称：世优（北京）科技股份有限公司<br>公司地址：北京市朝阳区酒仙桥路电通创意广场2号楼E区<br>合作咨询：17610082292<br>合作邮箱：3599215495@qq.com</div>
          </div>
          <div id="w-node-_52689639-77f9-af09-ed7f-733cbb12f432-bb12f40b" class="footer__sm" style="margin-right: 100px">
            <h6 class="footer__heading">公众号</h6>
            <div class="footer__sm-wrapper">
              <a href="#" class="footer__sm-link w-inline-block"><img src="../assets/images/公众号.png" loading="lazy" width="Auto" height="Auto" alt="" class="image-5"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Utiles from '@/utils/utiles'
import mammoth from 'mammoth'

export default {
  name: 'MainPage',
  data() {
    return {
      // 视频
      videoUrl: '',
      // 图片
      imgUrl: '',
      videoReady: false,
      wordText: '',
      isShowWord: false,
      wordURL: '',
      typeTitle: ''
    }
  },
  mounted() {
    Utiles.$on('clearSrc', () => {
      this.videoUrl = ''
      this.imgUrl = ''
    })
    Utiles.$on('playVideo', (val) => {
      this.videoUrl = val
      this.imgUrl = ''
      this.$nextTick(()=>{
        const targetElement = document.getElementById('my-video')
        const targetTop = targetElement.offsetTop
        // 平滑滚动到该位置
        window.scrollTo({
          top: targetTop,
          behavior: 'smooth'
        })
      })
    })
    Utiles.$on('playImg', (val) => {
      this.imgUrl = val
      this.videoUrl = ''
      this.$nextTick(()=>{
        const targetElement = document.getElementById('my-video')
        const targetTop = targetElement.offsetTop
        // 平滑滚动到该位置
        window.scrollTo({
          top: targetTop,
          behavior: 'smooth'
        })
      })
    })
  },
  methods: {
    // 打开word
    openWordFn(type) {
      this.typeTitle = type === 'user' ? '用户协议' : '隐私政策'
      if (type === 'privacy') {
        this.wordURL = 'https://mas-x.oss-cn-hangzhou.aliyuncs.com/doc/4u/%E4%B8%96%E4%BC%98%E7%A7%91%E6%8A%80%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.docx'
      }
      if (type === 'user') {
        this.wordURL = 'https://mas-x.oss-cn-hangzhou.aliyuncs.com/doc/4u/%E4%B8%96%E4%BC%98%E7%A7%91%E6%8A%80%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.docx'
      }
      this.getWordText()
      this.isShowWord = true
    },
    // 获取word信息
    getWordText() {
      const xhr = new XMLHttpRequest()
      xhr.open('get', this.wordURL, true)
      xhr.responseType = 'arraybuffer'
      xhr.onload = () => {
        if (xhr.status === 200) {
          mammoth.convertToHtml({ arrayBuffer: new Uint8Array(xhr.response) }).then((resultObject) => {
            this.$nextTick(() => {
              this.wordText = resultObject.value
            })
          })
        }
      }
      xhr.send()
    },
    // 打开备案
    handleClick() {
      window.open('https://beian.miit.gov.cn/state/outPortal/loginPortal.action#/Integrated/index', '_blank')
    },
    handleClose() {
      this.isShowWord = false
    }
  }
}
</script>
<style scoped>
::v-deep .el-dialog__body {
  height: 640px;
  overflow: auto;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #E6E6E6;
}

::v-deep .el-dialog {
  border-radius: 8px;
}

.word_context {
  padding: 0 50px 50px 50px;
}
.word_title {
  height: 52px;
  border-bottom: 1px solid #E6E6E6;
  line-height: 52px;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.word_view {
  text-align: left;
}
.word_btn{
  text-align: center;
}
.word_btn .el-button{
  width: 100px;
}
</style>