var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"nav-4 w-nav",attrs:{"data-collapse":"medium","data-animation":"default","data-duration":"400","data-easing":"ease","data-easing2":"ease","role":"banner"}},[_c('div',{staticClass:"nav__container-4 w-container"},[_vm._m(0),_c('nav',{staticClass:"nav__menu-4 w-nav-menu",attrs:{"role":"navigation","id":"w-node-a4a70760-d4e1-dbf0-85e2-a13ae5a4d453-e20a40ff"}},[_vm._m(1),_c('div',{staticClass:"nav__link-div-learning nav__link-div-learning--space w-dropdown",attrs:{"data-hover":"true","data-delay":"0"}},[_vm._m(2),_c('nav',{staticClass:"nav__dropdown w-dropdown-list"},[_c('div',{staticClass:"nav__dropdown-wrapper"},[_c('div',{staticClass:"nav__dropdown-arrow w-embed"},[_c('svg',{attrs:{"width":"11","height":"6","viewbox":"0 0 11 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.99996 0L10.5 6H0L4.99996 0Z","fill":"white"}})])]),_c('div',{staticClass:"nav__dropdown-bg-4"})]),_vm._m(3)])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)]),_vm._m(11),_vm._m(12)])]),_c('div',{staticClass:"nav-shadow-3"}),_vm._m(13)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__logo-4 w-nav-brand w--current",attrs:{"href":"/","id":"w-node-a4a70760-d4e1-dbf0-85e2-a13ae5a4d451-e20a40ff","aria-current":"page"}},[_c('img',{attrs:{"src":require("../assets/images/bota_logo_nav.png"),"loading":"lazy","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__link-div-2 nav__link-div--black nav__link-div--space w-inline-block w--current",attrs:{"href":"#hero","aria-current":"page"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav__link-wrapper-learning w-dropdown-toggle"},[_c('div',{staticClass:"nav__link-div-learning-arrow-4 w-icon-dropdown-toggle"}),_c('div',{staticClass:"nav__link-learning nav__link--8pxspace nav__link-learning--black"},[_vm._v("产品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav__dropdown-links-wrapper"},[_c('div',{staticClass:"nav__dropdown-links-div"},[_c('a',{staticClass:"nav__dropdown-link-div nav__dropdown-link-div--black nav__dropdown-link-div--space w-inline-block",attrs:{"href":"#botax"}},[_c('div',{staticClass:"nav__dropdown-link"},[_vm._v("实时互动数字人")])]),_c('a',{staticClass:"nav__dropdown-link-div nav__dropdown-link-div--black nav__dropdown-link-div--space w-inline-block",attrs:{"href":"#live"}},[_c('div',{staticClass:"nav__dropdown-link"},[_vm._v("数字人实时直播")])]),_c('a',{staticClass:"nav__dropdown-link-div nav__dropdown-link-div--black nav__dropdown-link-div--space w-inline-block",attrs:{"href":"#forcast"}},[_c('div',{staticClass:"nav__dropdown-link"},[_vm._v("气象节目")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__link-div-2 nav__link-div--black nav__link-div--space w-inline-block",attrs:{"href":"#tech"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("技术")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__link-div-2 nav__link-div--black nav__link-div--space nav__link-div--hidedesktop w-inline-block",attrs:{"href":"#"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("产品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__link-div-onlymobile-4 w-inline-block",attrs:{"href":"#botax"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("实时互动数字人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__link-div-onlymobile-4 w-inline-block",attrs:{"href":"#live"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("实时数字人直播")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__link-div-onlymobile-4 w-inline-block",attrs:{"href":"#forcast"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("气象节目系统")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav__link-div-jobs-2"},[_c('a',{staticClass:"nav__link-div-jobstext-2 nav__link-div-jobs--black w-inline-block",attrs:{"href":"#company"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("公司")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav__link-div-jobs-mobileonly-2 w-inline-block",attrs:{"href":"#company"}},[_c('div',{staticClass:"nav__link-4"},[_vm._v("公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav__buttons-2",attrs:{"id":"w-node-a4a70760-d4e1-dbf0-85e2-a13ae5a4d48d-e20a40ff"}},[_c('a',{staticClass:"button button--blue w-button",attrs:{"data-w-id":"a4a70760-d4e1-dbf0-85e2-a13ae5a4d490","href":"#"}},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav__mobilebutton-2 w-nav-button"},[_c('div',{staticClass:"nav__mobileicon-2 w-icon-nav-menu"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-wrap"},[_c('div',{staticClass:"modal-bg",attrs:{"data-w-id":"034d41ec-c500-d3c3-f32d-d32063fbf5a0"}}),_c('section',{staticClass:"testimonial-image-left"},[_c('a',{staticClass:"link-block w-inline-block",attrs:{"data-w-id":"594cc674-62d2-edb6-95f6-9fc714c209f4","href":"#"}},[_c('div',{staticClass:"modal-close"},[_vm._v("X")])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"testimonial-right"},[_c('div',{staticClass:"heading-small margin-bottom-32px"},[_vm._v("联系AI数字人专家为您服务")]),_c('img',{staticClass:"testimonial-image-four",attrs:{"src":require("../assets/images/客服二维码新logo.png"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"testimonial-title"},[_vm._v("扫一扫添加企业微信")]),_c('div',{staticClass:"modal-tel-bg"},[_c('h3',{staticClass:"heading-small"},[_vm._v("服务热线：176 1008 2292 ")])])])])])])
}]

export { render, staticRenderFns }