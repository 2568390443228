//必须引入的核心
import Recorder from 'recorder-core'
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
//录制wav格式的用这一句就行
import 'recorder-core/src/engine/wav'
import Utiles from '@/utils/utiles'

let RecorderIns = null
// 初始化
export function initRecord(opt = {}) {
  let defaultOpt = {
    serviceCode: "asr_aword",
    audioFormat: "wav",
    sampleRate: 16000,
    sampleBit: 16,
    audioChannels: 1,
    bitRate: 96000,
    audioData: null,
    punctuation: "true",
    model: null,
    intermediateResult: null,
    maxStartSilence: null,
    maxEndSilence: null,
  }

  let options = Object.assign({}, defaultOpt, opt);

  let sampleRate = options.sampleRate || 8000;
  let bitRate = parseInt(options.bitRate / 1000) || 16
  if (RecorderIns) {
    RecorderIns.close()
  }

  RecorderIns = Recorder({
    type: "wav",
    sampleRate: sampleRate,
    bitRate: bitRate,
    onProcess(buffers, powerLevel, bufferDuration, bufferSampleRate) {
      // 60秒时长限制
      const LEN = 59 * 1000;
      if (bufferDuration > LEN) {
        RecorderIns.recStop()
      }
    },
  })
  RecorderIns.open(
    () => {
      // 打开麦克风授权获得相关资源
      console.log("打开麦克风成功")
    },
    (msg, isUserNotAllow) => {
      // 用户拒绝未授权或不支持
      isUserNotAllow = false
      console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg);
    }
  )
}

window.StartRecord = function () {
  RecorderIns.start()
}

window.StopRecord = function() {
  RecorderIns.stop(
    (blob, duration) => {
    console.log(
      blob,   
      window.URL.createObjectURL(blob),
      "时长:" + duration + "ms"
    );
    Utiles.$emit('sendMsg', blob)
    },
    (msg) => {
      console.log("录音失败:" + msg)
    }
  )
}

// bota回复，根据你提问给你的回答
window.BotaText = function(text) {
  const json = JSON.parse(text)
  const image_url = json?.data?.image_url || ''
  const video_url = json?.data?.video_url || ''
  if (image_url) {
    Utiles.$emit('playImg', image_url)
  }
  if (video_url) {
    Utiles.$emit('playVideo', video_url)
  }
  // 创建一个正则表达式，匹配所有指定的特殊字符
  const regex = /[,.:;!?，。：；！？]/g
  // 使用split方法根据正则表达式切割文本
  const parts = json.data.answer.split(regex)
  // 过滤掉空数据
  const filteredParts = parts.filter(part => part !== '');

  // bota回复的是文字，需要tts(文字转音频)转成音频
  filteredParts.forEach(element => {
    Utiles.$emit('tounitytss', element)
  })
}

// asr-语音转文字
window.ASRText = function(text){
  Utiles.$emit('tounitytext', text)
}

window.SendPlayCompleteToNative = function(filePath) {
  // 在这里实现发送播放完成事件的逻辑
  console.log("### SendPlayCompleteToNative: " + filePath)
}

window.SendPlayAllCompleteToNative = function() {
  // 在这里实现发送全部播放完成事件的逻辑 回复都说完了，恢复idle
  Utiles.$emit('SendPlayAllCompleteToNative')
}

window.SendPlayMediaFinish = function(path) {
  // 在这里实现发送媒体播放完成事件的逻辑
  console.log("### SendPlayMediaFinish: " + path)
}

window.SendPlayAnimationFinish = function(name) {
  // 在这里实现发送动画播放完成事件的逻辑
  Utiles.$emit('SendPlayAnimationFinish', name)
}

window.UnitySendBSResultToNative = function(txt) {
  // 在这里实现发送表情完毕事件的逻辑
  console.log("### UnitySendBSResultToNative: " + txt)
}

window.UnitySendEvent = function(json) {
  const data = JSON.parse(json)
  console.log(data,'data-----data')
  // 说话结束事件
  if (data.type == 'SpeakStarted') {
    Utiles.$emit('UnitySendEvent')
  }
  // bota网络错误
  if (data.type == 'Error') {
    Utiles.$emit('tounityErrortss', '当前网络不稳定，请稍后再试吧')
  }
}

// 数字人加载进度
window.UnitySendProgress = function(text) {
  Utiles.$emit('sendProgress', text)
}
