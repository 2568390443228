import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import indexedDB  from '@/utils/indexdb.js'

await indexedDB.openDB('4u', 'sdk', 1).then((res) => {
  // 两种方式实现无处访问db实例
  // 封装文件夹时使用
  window.$db = res;
  // 页面单独请求使用
  Vue.prototype.$db = res;
});
Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
