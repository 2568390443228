<template>
    <div>
        <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="nav-4 w-nav">
            <div class="nav__container-4 w-container">
            <a href="/" id="w-node-a4a70760-d4e1-dbf0-85e2-a13ae5a4d451-e20a40ff" aria-current="page" class="nav__logo-4 w-nav-brand w--current"><img src="../assets/images/bota_logo_nav.png" loading="lazy" alt=""></a>
            <nav role="navigation" id="w-node-a4a70760-d4e1-dbf0-85e2-a13ae5a4d453-e20a40ff" class="nav__menu-4 w-nav-menu">
                <a href="#hero" aria-current="page" class="nav__link-div-2 nav__link-div--black nav__link-div--space w-inline-block w--current">
                <div class="nav__link-4">首页</div>
                </a>
                <div data-hover="true" data-delay="0" class="nav__link-div-learning nav__link-div-learning--space w-dropdown">
                <div class="nav__link-wrapper-learning w-dropdown-toggle">
                    <div class="nav__link-div-learning-arrow-4 w-icon-dropdown-toggle"></div>
                    <div class="nav__link-learning nav__link--8pxspace nav__link-learning--black">产品</div>
                </div>
                <nav class="nav__dropdown w-dropdown-list">
                    <div class="nav__dropdown-wrapper">
                    <div class="nav__dropdown-arrow w-embed"><svg width="11" height="6" viewbox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99996 0L10.5 6H0L4.99996 0Z" fill="white"></path>
                        </svg></div>
                    <div class="nav__dropdown-bg-4"></div>
                    </div>
                    <div class="nav__dropdown-links-wrapper">
                    <div class="nav__dropdown-links-div">
                        <a href="#botax" class="nav__dropdown-link-div nav__dropdown-link-div--black nav__dropdown-link-div--space w-inline-block">
                        <div class="nav__dropdown-link">实时互动数字人</div>
                        </a>
                        <a href="#live" class="nav__dropdown-link-div nav__dropdown-link-div--black nav__dropdown-link-div--space w-inline-block">
                        <div class="nav__dropdown-link">数字人实时直播</div>
                        </a>
                        <a href="#forcast" class="nav__dropdown-link-div nav__dropdown-link-div--black nav__dropdown-link-div--space w-inline-block">
                        <div class="nav__dropdown-link">气象节目</div>
                        </a>
                    </div>
                    </div>
                </nav>
                </div>
                <a href="#tech" class="nav__link-div-2 nav__link-div--black nav__link-div--space w-inline-block">
                <div class="nav__link-4">技术</div>
                </a>
                <a href="#" class="nav__link-div-2 nav__link-div--black nav__link-div--space nav__link-div--hidedesktop w-inline-block">
                <div class="nav__link-4">产品</div>
                </a>
                <a href="#botax" class="nav__link-div-onlymobile-4 w-inline-block">
                <div class="nav__link-4">实时互动数字人</div>
                </a>
                <a href="#live" class="nav__link-div-onlymobile-4 w-inline-block">
                <div class="nav__link-4">实时数字人直播</div>
                </a>
                <a href="#forcast" class="nav__link-div-onlymobile-4 w-inline-block">
                <div class="nav__link-4">气象节目系统</div>
                </a>
                <div class="nav__link-div-jobs-2">
                <a href="#company" class="nav__link-div-jobstext-2 nav__link-div-jobs--black w-inline-block">
                    <div class="nav__link-4">公司</div>
                </a>
                </div>
                <a href="#company" class="nav__link-div-jobs-mobileonly-2 w-inline-block">
                <div class="nav__link-4">公司</div>
                </a>
            </nav>
            <div id="w-node-a4a70760-d4e1-dbf0-85e2-a13ae5a4d48d-e20a40ff" class="nav__buttons-2">
                <a data-w-id="a4a70760-d4e1-dbf0-85e2-a13ae5a4d490" href="#" class="button button--blue w-button">联系我们</a>
            </div>
            <div class="nav__mobilebutton-2 w-nav-button">
                <div class="nav__mobileicon-2 w-icon-nav-menu"></div>
            </div>
            </div>
        </div>
        <div class="nav-shadow-3"></div>
        <div class="modal-wrap">
            <div data-w-id="034d41ec-c500-d3c3-f32d-d32063fbf5a0" class="modal-bg"></div>
            <section class="testimonial-image-left">
            <a data-w-id="594cc674-62d2-edb6-95f6-9fc714c209f4" href="#" class="link-block w-inline-block">
                <div class="modal-close">X</div>
            </a>
            <div class="container">
                <div class="testimonial-right">
                <div class="heading-small margin-bottom-32px">联系AI数字人专家为您服务</div><img src="../assets/images/客服二维码新logo.png" loading="lazy" alt="" class="testimonial-image-four">
                <div class="testimonial-title">扫一扫添加企业微信</div>
                <div class="modal-tel-bg">
                    <h3 class="heading-small">服务热线：176 1008 2292 </h3>
                </div>
                </div>
            </div>
            </section>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Navbar'
    }
</script>