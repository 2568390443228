// 引入elementUi的console.log组件
/*
 * 打开数据库
 * @param {object} dbName 数据库的名字
 * @param {string} storeName 仓库名称/表名称
 * @param {string} version 数据库的版本
 * @return {object} 该函数会返回一个数据库实例
 */
function openDB(dbName, storeName, version = 1) {
    return new Promise((resolve, reject) => {
        //  兼容浏览器
        let indexedDB =
            window.indexedDB ||
            window.mozIndexedDB ||
            window.webkitIndexedDB ||
            window.msIndexedDB,
            db;

        // 打开数据库，若没有则会创建
        const request = indexedDB.open(dbName, version);

        // 数据库打开成功回调
        request.onsuccess = function (result) {
            // 数据库对象
            db = result.target.result;
            resolve(db);
        };

        // 数据库打开失败的回调
        request.onerror = function (result) {
            reject(result);
        };

        // 数据库有更新时候的回调
        request.onupgradeneeded = function (result) {
            // 数据库创建或升级的时候会触发
            // 数据库对象
            db = result.target.result;
            // 创建存储库/也就是数据表
            let objectStore = db.createObjectStore(storeName, {
                // 这是主键
                // 主键使用的是数据表里的id值
                keyPath: "url",
                // 实现自增
                // autoIncrement: false

                // 如果有keyPath属性
                // 定义autoIncrement属性无效
                // 当没有keyPath属性时
                // 定义autIncrement属性有效
                // 且值必须为true
                // 否则报错
            });

            // 创建索引/属性，在后面查询数据的时候可以根据索引/属性查
            // 不能缺少的索引
            objectStore.createIndex("url", "url", { unique: false });
            // objectStore.createIndex("name", "name", { unique: false });
            // objectStore.createIndex("age", "age", { unique: false });
        };
    });
}

/*
 * 新增数据
 * @param {string} storeName 仓库名称/表名称
 * @param {string} data 数据
 */
function addData(storeName, key, data) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            // 事务对象 指定表格名称和操作模式（"只读"或"读写"）
            .transaction([storeName], "readwrite")
            // 仓库对象
            .objectStore(storeName)
            .add({url: key, data: data});

        request.onsuccess = function (result) {
            console.log({
                message: '添加成功',
                type: 'success'
            });
            resolve(result);
        };

        request.onerror = function (result) {
            console.log({
                message: '添加失败',
                type: 'error'
            });
            reject(result);
        };
    });
}

/*
 * 通过getData读取数据
 * @param {string} storeName 仓库名称/表名称
 * @param {string} keyname 表key
 */
function getData(storeName, key) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            // 事务
            .transaction(storeName, "readwrite")
            // 仓库对象
            .objectStore(storeName)
            // 指针对象
            .get(key);

        // 游标开启成功，逐行读数据
        request.onsuccess = function (result) {
            console.log({
                message: '获取成功',
                type: 'success'
            });
            resolve(result);
        };

        request.onerror = function (result) {
            console.log({
                message: '获取失败',
                type: 'error'
            });
            reject(result);
        };
    });
}

/*
 * 通过getAll读取数据
 * @param {string} storeName 仓库名称/表名称
 */
function getAll(storeName, key) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            // 事务
            .transaction(storeName, "readwrite")
            // 仓库对象
            .objectStore(storeName)
            // 指针对象
            .getAll();

        // 游标开启成功，逐行读数据
        request.onsuccess = function (result) {
            console.log({
                message: '获取成功',
                type: 'success'
            });
            resolve(result);
        };

        request.onerror = function (result) {
            console.log({
                message: '获取失败',
                type: 'error'
            });
            reject(result);
        };
    });
}

/*
 * 更新数据
 * @param {string} storeName 仓库名称/表名称
 * @param {object} data 数据
 */
function updateDB(storeName, key,data) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            // 事务对象
            .transaction([storeName], "readwrite")
            // 仓库对象
            .objectStore(storeName)
            .put({url: key, data:data});

        request.onsuccess = function (result) {
            console.log({
                message: '更新成功',
                type: 'success'
            });
            resolve(result);
        };

        request.onerror = function (result) {
            console.log({
                message: '更新失败',
                type: 'error'
            });
            reject(result);
        };
    });
}

/*
 * 通过主键删除数据
 * @param {string} storeName 仓库名称/表名称
 * @param {object} id 主键值
 */
function deleteDB(storeName, id) {
    return new Promise((resolve, reject) => {
        let request = window.$db
            .transaction([storeName], "readwrite")
            .objectStore(storeName)
            .delete(id);

        request.onsuccess = function (result) {
            console.log({
                message: '删除成功',
                type: 'success'
            });
            resolve(result);
        };

        request.onerror = function (result) {
            console.log({
                message: '删除失败',
                type: 'error'
            });
            reject(result);
        };
    });
}

let indexedDB = {
    deleteDB,
    updateDB,
    getData,
    addData,
    openDB,
    getAll,
};

export default indexedDB;
